import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi'
import ProjectFocusPoint from '../components/ProjectFocusPoint/ProjectFocusPoint'
import Hero from '../components/Hero/Hero'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import SingleTestimonial from '../components/SingleTestimonial/SingleTestimonial'
import '../styles/pages/project.scss'

export const ProjectTemplate = ({
  currentProject,
  testimonial,
  nextProject,
  prevProject,
}) => (
  <div className="project-template">
    <Hero
      bgImage={currentProject.featured_media.localFile.childImageSharp.fluid}
      bgOverlay="true"
      noMargin="true"
      type="fluid"
      title={currentProject.acf.main_title}
      imageAlt={`${currentProject.acf.main_title} - project intro background`}
      subtitleTop={currentProject.acf.sub_title_top}
      subtitleBottom={currentProject.acf.sub_title_bottom}
      url={currentProject.acf.url}
      scrollDown="true"
    />
    <div className="section is-medium">
      <div className="container">
        <div className="columns content-columns">
          <div
            className={`column ${
              testimonial ? 'is-5 is-offset-1' : 'is-6 is-offset-3'
            }`}
          >
            <h4 className="title no-underline is-size-3">The Project Brief</h4>
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: currentProject.acf.the_project_brief,
              }}
            />
          </div>
          {testimonial && (
            <div className="column is-5">
              <h4 className="title no-underline is-size-3">Client Feedback</h4>
              <SingleTestimonial
                image={currentProject.acf.testimonial_image}
                testimonial={testimonial}
              />
            </div>
          )}
        </div>
      </div>
    </div>

    {(currentProject.acf.focus_point_1_title ||
      currentProject.acf.focus_point_2_title) && (
      <div className="focus-points">
        {currentProject.acf.focus_point_1_title && (
          <ProjectFocusPoint project={currentProject} point="1" />
        )}

        {currentProject.acf.focus_point_2_title && (
          <ProjectFocusPoint project={currentProject} point="2" />
        )}
      </div>
    )}

    <div className="section is-medium">
      <div className="container">
        <div className="columns content-columns">
          <div className="column is-6 is-offset-3">
            <h4 className="title no-underline is-size-3">About The Client</h4>
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: currentProject.acf.about_the_client,
              }}
            />
          </div>
        </div>
      </div>
    </div>

    <div className="columns next-prev-projects has-text-centered is-hidden-mobile">
      <div className="column prev-project">
        <Link to={`/project/${prevProject.slug}`}>
          <Img
            fluid={
              prevProject.acf.project_grid_image_full.localFile.childImageSharp
                .fluid
            }
          />
          <div className="project-text">
            <span className="prev-text">Previous Project</span>
            <h5 className="subtitle is-size-5">
              {prevProject.acf.short_name
                ? prevProject.acf.short_name
                : prevProject.title}
            </h5>
            <FiArrowLeftCircle />
          </div>
        </Link>
      </div>
      <div className="column next-project">
        <Link to={`/project/${nextProject.slug}`}>
          <Img
            fluid={
              nextProject.acf.project_grid_image_full.localFile.childImageSharp
                .fluid
            }
          />
          <div className="project-text">
            <span className="next-text">Next Project</span>
            <h5 className="subtitle is-size-5">
              {nextProject.acf.short_name
                ? nextProject.acf.short_name
                : nextProject.title}
            </h5>
            <FiArrowRightCircle />
          </div>
        </Link>
      </div>
    </div>
  </div>
)

const Project = ({ data }) => (
  <Layout
    color={data.currentProject.acf.header_type}
    footerClass="reduced-margin-top"
  >
    <SEO
      title={data.currentProject.title}
      description={data.currentProject.acf.meta_description}
    />
    <ProjectTemplate
      currentProject={data.currentProject}
      testimonial={data.testimonial}
      nextProject={data.nextProject}
      prevProject={data.prevProject}
    />
  </Layout>
)

export default Project

export const projectQuery = graphql`
  query ProjectById(
    $id: String!
    $nextProjectId: String!
    $prevProjectId: String!
    $testimonialId: Int
  ) {
    currentProject: wordpressWpProjects(id: { eq: $id }) {
      title
      content
      acf {
        url
        main_title
        sub_title_top
        sub_title_bottom
        the_project_brief
        about_the_client
        header_type
        focus_point_1_title
        focus_point_1_subtitle
        focus_point_1_text
        focus_point_1_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        focus_point_2_title
        focus_point_2_subtitle
        focus_point_2_text
        focus_point_2_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        focus_point_bg_colour
        focus_point_text_colour
        focus_point_bg_colour_2
        focus_point_text_colour_2
        meta_description
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    testimonial: wordpressWpTestimonials(wordpress_id: { eq: $testimonialId }) {
      title
      content
      featured_media {
        localFile {
          childImageSharp {
            fixed(width: 75, height: 75, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
    nextProject: wordpressWpProjects(id: { eq: $nextProjectId }) {
      title
      slug
      acf {
        short_name
        project_grid_image_full {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    prevProject: wordpressWpProjects(id: { eq: $prevProjectId }) {
      title
      slug
      acf {
        short_name
        project_grid_image_full {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { v4 } from 'uuid'
import { chunk } from 'lodash'
import './SingleTestimonial.scss'

const SingleTestimonial = ({ image, testimonial }) => (
  <div className="testimonial-wrapper container">
    <div
      className="content"
      dangerouslySetInnerHTML={{ __html: testimonial.content }}
    />
    <div
      className="author"
      dangerouslySetInnerHTML={{ __html: testimonial.title }}
    />
  </div>
)

export default SingleTestimonial

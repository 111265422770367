import React from 'react'

import './ProjectFocusPoint.scss'

const Color = require('color')

class ProjectFocusPoint extends React.Component {
  render() {
    const { project, point } = this.props

    return (
      <div
        className={`focus-point-wrapper focus-point-${point} columns`}
        style={{
          borderColor: Color(project.acf.focus_point_bg_colour_2).darken(0.25),
        }}
      >
        <div className="column is-6-tablet is-4-desktop">
          <div
            className="content-wrapper"
            style={{
              backgroundColor: project.acf.focus_point_bg_colour,
              color: project.acf.focus_point_text_colour,
            }}
          >
            <div className="text content">
              <div
                dangerouslySetInnerHTML={{
                  __html: project.acf[`focus_point_${point}_text`],
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="column is-6-tablet is-8-desktop image-wrapper has-text-centered"
          style={{
            backgroundColor: project.acf.focus_point_bg_colour_2,
            color: project.acf.focus_point_text_colour_2,
          }}
        >
          <div className="text-wrapper">
            <h3 className="title no-transform">
              {project.acf[`focus_point_${point}_title`]}
            </h3>
            <p>{project.acf[`focus_point_${point}_subtitle`]}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default ProjectFocusPoint
